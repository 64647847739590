<template>
  <!-- style="height: 401px; max-height: 401px;" -->
  <v-card-text class="pa-6">
    <v-row v-if="!evento.adm_id && !evento.login_radius">
      <v-col cols="12">
        Adm ID deste cliente nao pode ser encontrado!
      </v-col>
      <!-- adm_id -->
      <v-col cols="12" md="3">
        <v-text-field
          type="number"
          v-model="adm_id"
          label="Adm ID"
          dense
          filled
          hide-details
          @blur="buscaAdmCliente"
          :loading="buscandoAdmCliente"
        ></v-text-field>
      </v-col>

      <!-- Login Radius -->
      <v-col cols="12" md="6">
        <v-text-field
          v-model="login_radius"
          label="Login Radius"
          dense
          filled
          hide-details
          readonly
          :placeholder="!adm_id ? 'Adm ID primeiro' : ''"
          :disabled="adm_id ? false : true"
        ></v-text-field>
      </v-col>

      <!-- Login Radius -->
      <v-col cols="12" md="3">
        <v-btn
          depressed
          color="buttons"
          class="white--text"
          height="54px"
          :disabled="adm_id && login_radius ? false : true"
          @click="updateCliente"
        >
          Atualizar Cliente
          <v-icon right>save</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else-if="!checkingCliente">
      <!-- Status -->
      <v-col cols="12">
        <v-card outlined>
          <v-card-title class="text-body-2 lightgray pa-3" v-if="radacct">
            Status de conexão do cliente:
            <v-spacer></v-spacer>
            <div v-if="radacct.acctstoptime" class="font-weight-bold">
              DESCONECTADO A
              {{ conectadoDiff(radacct.acctstoptime).toUpperCase() }}
            </div>
            <div class="text-subtitle-1 font-weight-bold" v-else>
              CONECTADO A
              {{ conectadoDiff(radacct.acctstarttime).toUpperCase() }}
            </div>
          </v-card-title>
          <v-card-title class="text-body-2 lightgray pa-3" v-else>
            Status de conexão do cliente:
            <v-spacer></v-spacer>
            <div class="text-subtitle-1 font-weight-bold">
              SEM CONEXÃO
            </div>
          </v-card-title>
        </v-card>
      </v-col>

      <!-- Radacct -->
      <v-col cols="12" v-if="radacct">
        <v-card outlined>
          <v-card-title class="text-body-2 px-3 py-2 lightgray">
            Radacct
          </v-card-title>
          <v-divider></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Chave
                  </th>
                  <th class="text-left">
                    Valor
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, key, index) in radacct" :key="index">
                  <td>{{ key }}</td>
                  <td v-if="key === 'acctstarttime' || key === 'acctstoptime'">
                    {{ value | dateFormat("dd/MM/yyyy HH:mm:ss") }}
                  </td>
                  <td v-else>{{ value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>

      <!-- Registro -->
      <v-col cols="12" v-if="sis_cliente">
        <v-card outlined>
          <v-card-title class="text-body-2 px-3 py-2 lightgray">
            Registro
          </v-card-title>
          <v-divider></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Chave
                  </th>
                  <th class="text-left">
                    Valor
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, key, index) in sis_cliente" :key="index">
                  <td>{{ key }}</td>
                  <td
                    v-if="
                      key === 'data_bloq' ||
                        key === 'data_desbloq' ||
                        key === 'data_desativacao'
                    "
                  >
                    {{ value | dateFormat("dd/MM/yyyy HH:mm:ss") }}
                  </td>
                  <td v-else>{{ value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <Carregando v-else />
  </v-card-text>
</template>

<script>
import { getClienteAdm, putCliente } from "@/services/api/clientes.api.js";
import { getUltConexao, getSisCliente } from "@/services/api/conexoes.api.js";
import { format, parseISO, formatDistance } from "date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import { mapState, mapActions } from "vuex";

export default {
  name: "EventoConexoes",

  data() {
    return {
      loading: false,
      ult_conexao: {},
      adm_id: null,
      login_radius: null,
      buscandoAdmCliente: false,
      radacct: null,
      sis_cliente: null,
      checkingCliente: false,
    };
  },

  computed: {
    ...mapState("Evento", {
      evento: (state) => state.evento,
    }),
    evento_id() {
      return this.$route.params.eventoId;
    },
    evento_adm_id() {
      return this.evento.adm_id;
    },
    evento_login_radius() {
      return this.evento.login_radius;
    },
  },

  watch: {
    evento_adm_id: {
      handler() {
        if (this.evento_adm_id) {
          this.adm_id = this.evento_adm_id;
        }
      },
      immediate: true,
    },
    evento_login_radius: {
      handler() {
        if (this.evento_login_radius) {
          this.login_radius = this.evento_login_radius;

          this.checkCliente();
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions("Evento", {
      getEvento: "getEvento",
      reset: "reset",
    }),
    conectadoDiff(campo) {
      if (this.radacct) {
        return formatDistance(new Date(), parseISO(campo), {
          locale: ptBrLocale,
        });
      } else {
        return "";
      }
    },
    buscaAdmCliente() {
      if (this.adm_id) {
        this.buscandoAdmCliente = true;
        getClienteAdm(this.adm_id)
          .then((response) => {
            this.login_radius = response.pppoe;

            if (this.evento.cliente != response.nome) {
              this.$toast.error("Nome de cliente nao bate com ADM");
            }

            this.buscandoAdmCliente = false;
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.$toast.error("Adm id incorreto ou nao encontrado!");
            }
            this.buscandoAdmCliente = false;
          });
      }
    },
    updateCliente() {
      let body = {
        adm_id: this.adm_id,
        login_radius: this.login_radius,
      };
      this.$Progress.start();
      putCliente(this.evento.cliente_id, body)
        .then(() => {
          this.getEvento(this.evento_id);
          this.$toast.success("Cliente atualizado com sucesso!");
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        });
    },
    async checkCliente() {
      this.checkingCliente = true;
      this.radacct = null;
      this.sis_cliente = null;

      await getUltConexao(`?pppoe=${this.login_radius}`)
        .then((response) => {
          this.radacct = response;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.$toast.error("No hay nenguna conexion en radius!");
          }
        });

      await getSisCliente(`?pppoe=${this.login_radius}`)
        .then((response) => {
          this.sis_cliente = response;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.$toast.error("No hay nengun registro en radius!");
          }
        });

      this.checkingCliente = false;
    },
  },

  mounted() {},
};
</script>

<style></style>
