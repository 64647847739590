import api from "../httpClient.js";

export async function getUltConexao(filtros) {
  const response = await api.get(`/ultima-conexao${filtros ? filtros : ""}`);
  return response.data.connection;
}

export async function getSisCliente(filtros) {
  const response = await api.get(`/sis-cliente${filtros ? filtros : ""}`);
  return response.data.sis_cliente;
}
